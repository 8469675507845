<!-- eslint-disable max-len -->
<template>
    <div class="email-validate">
        <div
            v-if="status=='doing'"
            class="status"
        >
            <img
                class="status-img"
                src="../../assets/register/email.png"
                alt=""
            >
            <div class="text">
                <div class="color-orange">
                    {{ $t('Register.EmailValidate.text[0]') }}
                </div>
                <div class="">
                    {{ $t('Register.EmailValidate.text[1]') }}
                </div>
            </div>
        </div>
        <div
            v-if="status=='failed'"
            class="status"
        >
            <img
                class="status-img"
                src="../../assets/register/failed.png"
                alt=""
            >
            <div class="text">
                <div class="colof-gray">
                    {{ $t('Register.EmailValidate.text[2]') }}
                </div>
                <div style="color:#ADADAD">
                    {{ $t('Register.EmailValidate.text[3]') }}
                </div>
            </div>
        </div>
        <div
            v-if="confirmForm.account"
            class="submit-btn"
            :class="{'btn-disable':!confirmEnable}"
            @click="resentConfirmEmail"
        >
            {{ $t('Register.EmailValidate.text[4]') }}<span v-if="!confirmEnable">({{ count }})</span>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import API from '@/api';
import { mapMutations } from 'vuex';

export default {
    components: {},
    data() {
        return {
            count: 60,
            status: 'doing',
            confirmForm: {
                account: '',
                code: '',
            },
        };
    },
    computed: {
        token() {
            return _.get(this.$route, 'query.token');
        },
        account() {
            return _.get(this.$route, 'query.account');
        },
        confirmEnable() {
            if (this.count > 0) {
                return false;
            }
            return true;
        },
    },
    created() {
        this.init();
    },
    mounted() {
        this.countStart();
    },
    methods: {
        ...mapMutations('mNotification', ['setAlert', 'handleError']),
        ...mapMutations('mLoading', ['setLoading']),
        init() {
            if (this.token) {
                try {
                    const result = JSON.parse(atob(this.token));

                    if (result) {
                        this.confirmForm.account = result.account;
                        this.confirmForm.code = result.password;
                        console.log(result);
                        this.confirm();
                    } else {
                        this.status = 'failed';
                    }
                } catch (error) {
                    this.status = 'failed';
                }
            }
            if (this.account) {
                this.confirmForm.account = this.account;
            }
        },
        confirm() {
            this.setLoading(true);
            API.User.ConfirmRegisterOtp(this.confirmForm)
                .then((res) => {
                    console.log(res.data);
                    this.$router.push({ name: 'RegisterValidateSuccess' });
                    this.setLoading(false);
                })
                .catch((e) => {
                    console.log(e);
                    this.setLoading(false);
                    this.setAlert({
                        message: this.$t('alertMessage.check-fail'),
                        color: 'error',
                    });
                    this.status = 'failed';
                });
        },
        resentConfirmEmail() {
            if (!this.confirmEnable) {
                return;
            }
            this.setLoading(true);
            if (this.confirmForm.account) {
                API.User.SendRegisterOtp({ account: this.confirmForm.account })
                    .then(() => {
                        this.setAlert({
                            message: this.$t('alertMessage.check-again'),
                            color: 'success',
                        });
                        this.countStart();
                        this.setLoading(false);
                    })
                    .catch((e) => {
                        this.handleError({
                            message: e || this.$t('alertMessage.send-fail'),
                            color: 'error',
                        });
                        this.setLoading(false);
                    });
            }
        },
        countStart() {
            this.count = 60;
            this.countInterval = setInterval(() => {
                this.count -= 1;
                if (this.count === 0) {
                    clearInterval(this.countInterval);
                }
            }, 1000);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/scss/register.scss";
.email-validate{
  .btn-disable{
    cursor: not-allowed;
    background-color: #808080
  }
}

</style>
